
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.desc {
    color: #666;
}
.icon {
    // width: 80px;
    height: 60px;
    @media screen and (max-width: 423px) {
        height: 40px;
    }
}
.lnkTitle {
    color: #000;
}
.region {
    font-size: 11px;
    color: #999;
    margin: 0 auto 10px;
    width: 80% !important;
}
.serviceBlocks {
    max-width: 600px;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 628px) {
        padding: 0 30px;
    }
    @media screen and (max-width: 503px) {
        padding: 0 30px;
        justify-content: center;
    }
    @media screen and (max-width: 423px) {
        padding: 0 15px;
    }
}
.serviceBlockLnk {
    font-size: 12px;
    cursor: pointer;
    min-height: 65px;
    border: 1px solid #be1e20;
    position: relative;
    background-color: rgb(247, 247, 247);
    padding: 15px;
    box-shadow: 0px 10px 10px -8px rgb(0 0 0 / 15%);
    flex: 0 0 30.6%;
    @media screen and (max-width: 628px) {
        flex: 0 0 46.9%;
    }
    @media screen and (max-width: 619px) {
        flex: 0 0 46%;
    }
    @media screen and (max-width: 503px) {
        flex: 0 0 45%;
    }
    @media screen and (max-width: 423px) {
        flex: 0 0 41%;
        padding: 10px 4px;
        span:last-child {
            font-size: 13px !important;
        }
    }

    input {
        position: absolute;
        opacity: 0;
    }
    svg {
        width: 80px;
        height: auto;
        @media screen and (max-width: 423px) {
            width: 45px;
        }
    }

    .auto-accident {
        svg {
            width: 60px;
            @media screen and (max-width: 423px) {
                width: 45px;
            }
        }
    }
    .personal-injury {
        svg {
            width: 45px;
            @media screen and (max-width: 423px) {
                width: 34px;
            }
        }
    }
    .worker-compensation {
        svg {
            width: 45px;
            @media screen and (max-width: 423px) {
                width: 34px;
            }
        }
    }
    .paraquat {
        svg {
            width: 45px;
            @media screen and (max-width: 423px) {
                width: 34px;
            }
        }
    }
    .camp-lejeune {
        svg {
            width: 45px;
            position: relative;
            top: 3px;
            @media screen and (max-width: 423px) {
                width: 36px;
            }
        }
    }
    .talcum-powder {
        svg {
            width: 60px;
            position: relative;
            top: 5px;
            @media screen and (max-width: 423px) {
                width: 40px;
            }
        }
    }
    .zantac {
        svg {
            width: 50px;
            @media screen and (max-width: 423px) {
                width: 34px;
            }
        }
    }
    .military-earplugs {
        svg {
            width: 35px;
            @media screen and (max-width: 423px) {
                width: 28px;
            }
        }
    }

    i {
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        display: inline-block;
        border-radius: 30px;
    }

    &:hover {
        background-color: #be1e20;
        color: #fff;
        .desc {
            color: #fff;
        }
        svg path {
            fill: #fff;
        }
        .lnkTitle {
            color: #fff;
        }
    }
}

a.hurtIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 70%;
        }
    }
}
a.productHarmIcon:hover {
    svg {
        path[fill] {
            fill: #fff;
        }
        path[stroke] {
            stroke: #fff;
        }
    }
}
a.socialSecurityDisabilityIcon:hover {
    svg {
        path[fill] {
            fill: #fff;
        }
        path[stroke] {
            stroke: #fff;
        }
    }
}
a.socialSecurityDisabilityIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 70%;
        }
    }
}
a.drugIcon {
    @media screen and (max-width: 767px) {
        svg {
            width: 50%;
        }
    }
}

.mainBanner {
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    min-height: calc(100vh - 54px);
    @media screen and (max-width: 730px) {
        padding: 0 20px;
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-image: url("/assets/images/background2.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 2;
        @media screen and (max-width: 414px) {
            background-image: url("/assets/images/background2.png");
            background-size: cover;
            background-color: #c4c4c4;
            // background-repeat: no-repeat;
            background-position: bottom center;
        }
    }
}
.errorMessage {
    color: $error;
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: -20px;
}
.form {
    max-width: 520px;
    margin: 0 auto;
    padding-bottom: 10px;
    z-index: 5;
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.form-step-0 {
    min-height: 327px;
}
.formWrapper {
    background: #fdfdfd;
    box-shadow: 0px 4px 7px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 450px;
    max-width: 700px;
    width: 100%;
    position: relative;
    z-index: 3;
    margin-top: 50px;
    @media screen and (max-width: 767px) {
        margin-top: 25px;
    }
}
.mainTitle {
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    font-weight: 700;
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto 20px;
    @media screen and (max-width: 800px) {
        max-width: 560px;
        margin-bottom: 30px;
    }
}
.form-button {
    background-color: $injurycompensation-default-button-color !important;
    color: #fff !important;
    min-height: 50px !important;
    font-size: 16px;
    &:hover {
        background-color: $injurycompensation-default-button-hover-color !important;
    }
}
.form-field {
    max-width: unset;
    margin: 0 auto;
    margin-bottom: 0.5rem;
}
.form-field input[type="text"],
.form-field input[type="tel"],
.form-field input[type="email"],
.form-field input[type="number"] {
    font-family: "Roboto", sans-serif;
    min-height: 50px !important;
    font-size: 16px;
}
.form-field select {
    @media screen and (max-width: 767px) {
        min-height: 45px !important;
    }
}
.back-button {
    padding: 5px 15px;
    border-radius: 5px;
    left: 72px !important;
    bottom: 10px !important;
    @media screen and (max-width: 630px) {
        left: 5px !important;
    }
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}
.radio-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 10px;

    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                font-weight: 500;
                background-color: $injurycompensation-radio-default-hover-button-bgcolor;
                border-color: $injurycompensation-radio-default-hover-button-bgcolor;
                color: $injurycompensation-radio-default-hover-button-text-color;
            }
        }
        label {
            border-radius: 3px;
            background-color: #fff;
            border: 1px solid
                $injurycompensation-radio-default-hover-button-bgcolor;
            color: #000;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 700;
            &:hover {
                background-color: $injurycompensation-radio-default-hover-button-bgcolor;
                color: white;
            }
            span {
                font-size: 14px !important;
                @include md {
                    font-size: 16px !important;
                }
            }
            @include md {
                min-height: 50px;
            }
            svg {
                margin-bottom: 10px;
                width: 30px;
                height: auto;
            }
        }
        @include md {
            margin: 0 10px 15px;
            width: 60% !important;
            margin-left: auto;
            margin-right: auto;
        }
        &:last-child {
            margin-bottom: 10px !important;
        }
    }
    @include lg {
        margin: 0 -10px;
    }
}

.bestLawyer {
    font-size: 11px;
    color: #999;
}

.steps-inner {
    flex-grow: 1;
}
.fields-wrapper {
    margin-top: 1.5rem;
}

.step-block {
    select,
    input {
        width: 100% !important;
    }
}

.step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
        font-size: 16px !important;
    }
}
.label-as-step-title {
    color: #000;
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 15px !important;
}
.step-description {
    text-align: center;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}

.outsideTitle {
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}
.outsideBrief {
    color: #000;
    max-width: 565px;
}

.form-field input[type="date"] {
    min-width: 94%;
}
.submit-button {
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background-color: rgba(255, 255, 255, 0.2);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
        animation: blink 2.4s ease infinite;
    }
}

.custom-progress {
    div:nth-child(2) {
        span {
            background-color: $lawyer-consultation-progress !important;
        }
    }
    div {
        &:first-child {
            color: $lawyer-consultation-progress !important;
            background-color: white !important;
            border: 1px solid $lawyer-consultation-progress !important;
            span {
                &:last-child {
                    border-bottom-color: $lawyer-consultation-progress !important;
                }
            }
        }
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(40rem);
    }
}
.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.mainBannerWrapper {
    width: 100%;
    max-width: 700px;
}
.formStepsWrapper {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    overflow: hidden;
    padding-top: 30px;
    justify-content: center;
    padding-bottom: 30px;

    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
            padding-top: 20px !important;
        }
        .brief {
            max-width: 500px;
            margin: 0 auto 50px;
        }

        & > .buttons {
            width: 100%;
        }
        .back-button {
            bottom: -20px !important;
        }
    }
}

.formStepsWrapperWindow {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    width: 100%;
    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .description {
            margin: 10px 0;
        }

        .buttons {
            width: 100%;
        }
    }
}

.progress-bar {
    margin: 0 auto 20px !important;
    width: 80% !important;
}

.auto-accident {
    .step-block:nth-of-type(1) {
        .radio-field {
            margin: 10px;
            > div {
                width: 100% !important;
                input {
                    &:checked + label {
                        > div {
                            > img {
                                filter: brightness(0) saturate(100%) invert(90%)
                                    sepia(91%) saturate(23%) hue-rotate(8deg)
                                    brightness(107%) contrast(107%);
                            }
                        }
                    }
                }
                > label {
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 10px;
                    padding-top: 0;
                    padding-bottom: 0;
                    > div {
                        width: 45px;
                        height: 45px;
                        > img {
                            width: 100%;
                            height: 100%;
                            filter: brightness(0) saturate(100%) invert(0%)
                                sepia(100%) saturate(7445%) hue-rotate(144deg)
                                brightness(80%) contrast(112%);
                        }
                    }
                    > span {
                        text-align: left;
                    }
                    &:hover {
                        > div {
                            > img {
                                filter: brightness(0) saturate(100%) invert(90%)
                                    sepia(91%) saturate(23%) hue-rotate(8deg)
                                    brightness(107%) contrast(107%);
                            }
                        }
                    }
                }
            }
        }
    }
}
