$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;

$payrollsystems-footer-text-color: #000;
$payrollsystems-footer-separator-color: #006336;
$payrollsystems-offer-call-number-color: #006336;
$payrollsystems-why-us-brief-color: #747474;
$payrollsystems-default-button-color: #006336;
$payrollsystems-default-button-hover-color: #066d3e;
$payrollsystems-default-radio-selected-bgcolor: #e4f2ec;
$payrollsystems-default-radio-text-color: #000;
$payrollsystems-default-progress-color: #9bc7af;
$payrollsystems-default-progress-bgcolor: #e8f3f8;
$payrollsystems-default-progress-buble-text-color: #013d21;

$injurycompensation-default-button-color: #be1e20;
$injurycompensation-default-button-hover-color: #af1b1e;

$injurycompensation-radio-default-button-bgcolor: #fff;
$injurycompensation-radio-default-hover-button-bgcolor: #be1e20;
$injurycompensation-radio-default-hover-button-text-color: #fff;
$injurycompensation-radio-default-button-text-color: #000;
$injurycompensation-offer-call-number: #ac312a;

:export {
    green: $businessphoneprices-green;
    whyUsBriefColor: $payrollsystems-why-us-brief-color;
    offerCallNumber: $injurycompensation-offer-call-number;
    defaultButtonColor: $payrollsystems-default-button-color;
    defaultProgressColor: $payrollsystems-default-progress-color;
}
